import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "twin.macro";

import Disclaimer from "../../../components/Disclaimer";
import { PageLoader } from "../../../components/PageLoader";
import PDFJSBackend from "../../../components/PDFViewer/pdfjs";
import PDFViewer from "../../../components/PDFViewer/PDFViewer";
import { Api } from "../../../services/api/Api";
import { useDocPrefix } from "../../../utils/common";

const viewerSize = css`
  width: 100%;
  height: 82vh;
`;

const TermsOfUse: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const api = new Api();
  const [srcUrl, setSrcUrl] = useState<string | null>(null);

  const defaultPdf = "terms-of-use.pdf";
  const docPrefix = useDocPrefix();
  const pdfLink = docPrefix + defaultPdf;

  async function getLink() {
    const terms = await api.term.find();
    const termFiles = terms.data[0]?.tFiles;
    if (terms.data.length > 0) {
      let latestFile = termFiles[0]?.originalUrl;
      termFiles.forEach(file => {
        if (file.language === language) {
          latestFile = file.originalUrl;
        }
      });
      if (latestFile !== undefined) {
        setSrcUrl(latestFile);
      }
    } else {
      setSrcUrl(pdfLink);
    }
  }

  getLink();
  return (
    <Disclaimer pdf={srcUrl || pdfLink}>
      {srcUrl && <PDFViewer backend={PDFJSBackend} src={srcUrl} />}
      {!srcUrl && <PageLoader css={viewerSize} />}
    </Disclaimer>
  );
};

export default TermsOfUse;
